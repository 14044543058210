import React from 'react';

const IconYouTube = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="feather feather-youtube">
    <title>YouTube</title>
    <path d="M22.54 6.42a2.78 2.78 0 0 0-1.94-1.94C18.88 4 12 4 12 4s-6.88 0-8.6.48a2.78 2.78 0 0 0-1.94 1.94C1 8.12 1 12 1 12s0 3.88.46 5.6a2.78 2.78 0 0 0 1.94 1.94C5.12 20 12 20 12 20s6.88 0 8.6-.46a2.78 2.78 0 0 0 1.94-1.94c.46-1.72.46-5.6.46-5.6s0-3.88-.46-5.6z"></path>
    <polygon points="9.75 15.02 9.75 8.98 15.5 12 9.75 15.02"></polygon>
  </svg>
);

export default IconYouTube;
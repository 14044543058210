import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';

const StyledJobsSection = styled.section`
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
`;

const ScrollableContent = styled.div`
  max-height: 500px; // Set a max height to make this part scrollable
  overflow-y: auto; // Enable vertical scrolling within this container
  scrollbar-width: thin;
  scrollbar-color: var(--dark-slate) var(--navy);

  ::-webkit-scrollbar {
    width: 12px;
  }
  ::-webkit-scrollbar-track {
    background: var(--navy);
  }
  ::-webkit-scrollbar-thumb {
    background-color: var(--dark-slate);
    border: 3px solid var(--navy);
    border-radius: 10px;
  }
`;


const JobArticle = styled.div`
  opacity: 0.8;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 8px;
  padding: 1rem;
  position: relative;
  margin-bottom: 12px;
  transition: all 0.25s ease-in-out;

  &:hover {
    opacity: 1;
    background-color: rgba(10, 25, 47, 0.9); /* Adjust the color to match your theme */
    box-shadow: inset 0 1px 0 0 rgba(148, 163, 184, 0.1), 0 10px 20px rgba(0, 0, 0, 0.19);
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }

  header {
    grid-column: span 2;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
    color: #ccc; /* Adjust the color to match your theme */
  }

  div {
    grid-column: span 6;
  }

  h3 {
    font-size: 16px;
    color: #ddd; /* Adjust the color to match your theme */
    margin: 0;

    a {
      color: var(--green);
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

  }

  p {
    color: #aaa; /* Adjust the color to match your theme */
    font-size: 14px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    align-items: center;
    margin: 10px 0 0 0;
    list-style: none;

    li {
      margin-right: 15px;
      display: flex;
      align-items: center;

      div {
        background: rgba(56, 178, 172, 0.1); /* Light teal background */
        color: #38B2AC; /* Teal text color */
        border-radius: 9999px;
        padding: 5px 15px;
        font-size: 12px;
        font-weight: medium; /* Slightly bolder text */
        text-transform: uppercase; /* Makes the text uppercase */
        margin-bottom: 10px;
        }
    }
  }
`;

const Jobs = () => {
  const data = useStaticQuery(graphql`
    query {
      jobs: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/jobs/" } },
        sort: { fields: [frontmatter___date], order: DESC }
      ) {
        edges {
          node {
            frontmatter {
              title
              company
              location
              range
              url
              skills
              location
            }
            html
          }
        }
      }
    }
  `);

  const jobsData = data.jobs.edges;

  return (
    <StyledJobsSection id="jobs">
      <h2 className="numbered-heading">Where I’ve Worked</h2>
      <ScrollableContent>
        {jobsData.map(({ node }, i) => {
          const { frontmatter, html } = node;
          const { title, url, company, skills, range, location} = frontmatter;
          console.log('Job Data Node:', node);
          console.log('Skills for job:', title, skills); // Debugging line to check the value of skills


          return (
            <JobArticle key={i}>
              <header>{range}</header>
              <div>
                <h3>
                  {title} - <a href={url} target="_blank" rel="noopener noreferrer">{company}</a>
                </h3>
                <header>{location}</header>
                <p dangerouslySetInnerHTML={{ __html: html }} />
                {Array.isArray(skills) && skills.length > 0 ? (
                <ul>
                  {skills.map((skill, index) => (
                    <li key={index}>
                      <div>{skill}</div>
                    </li>
                  ))}
                </ul>
              ) : (
                <p></p> // Optionally, handle the case when skills are not available
              )}
              </div>
            </JobArticle>
          );
        })}
      </ScrollableContent>
    </StyledJobsSection>
  );
};

export default Jobs;

